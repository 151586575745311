<template>
	<div>
		<b-modal
			ref="modal-kardex"
			:title="`Kardex`"
			@hidden="$emit('close')"
			cancel-title="Cancelar"
			scrollable
			title-tag="h3"
			size="xmd"
		>
			<div>
				<b-row>
					<b-col md="6">
						<b-form-group label="Codigo">
							<b-form-input
								id="code"
								type="text"
								v-model="form.code"
								placeholder="Codigo producto..."
								readonly
							/>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label="Producto">
							<b-form-input
								id="code"
								type="text"
								v-model="form.name"
								placeholder="Nombre producto..."
								readonly
							/>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<ValidationProvider v-slot="{ errors }" rules="required" class="w-100">
							<b-form-group :state="errors[0] ? false : null" label="Categoria">
								<b-form-input
									id="name"
									:state="errors[0] ? false : null"
									type="text"
									v-model="info.category"
									placeholder=""
									readonly
								/>
							</b-form-group>
						</ValidationProvider>
					</b-col>

					<b-col md="3">
						<ValidationProvider class="w-100">
							<b-form-group label="Presentacion">
								<b-form-input
									id="presentation"
									type="text"
									v-model="info.measurement_unit"
									placeholder=""
									readonly
								/>
							</b-form-group>
						</ValidationProvider>
					</b-col>

					<b-col md="3">
						<ValidationProvider v-slot="{ errors }" rules="required" class="w-100">
							<b-form-group :state="errors[0] ? false : null" label="Stock actual">
								<b-form-input
									id="current_stock"
									:state="errors[0] ? false : null"
									type="text"
									v-model="info.current_stock"
									placeholder=""
									readonly
								/>
							</b-form-group>
						</ValidationProvider>
					</b-col>
				</b-row>

				<b-card no-body class="mb-0">
					<div class="m-1">
						<!-- Paginator -->
						<div class="mx-1 mb-1">
							<b-row>
								<b-col
									cols="12"
									sm="6"
									class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
								>
									<span class="text-muted">
										Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
									</span>
								</b-col>
								<!-- Pagination -->
								<b-col
									cols="12"
									sm="6"
									class="d-flex align-items-center justify-content-center justify-content-sm-end"
								>
									<b-pagination
										v-model="current_page"
										:total-rows="total_data"
										:per-page="perpage"
										first-number
										last-number
										class="mb-0 mt-1 mt-sm-0"
										prev-class="prev-item"
										next-class="next-item"
									>
										<template #prev-text>
											<feather-icon icon="ChevronLeftIcon" size="18" />
										</template>
										<template #next-text>
											<feather-icon icon="ChevronRightIcon" size="18" />
										</template>
									</b-pagination>
								</b-col>
							</b-row>
						</div>

						<b-row>
							<b-col
								cols="12"
								xl="4"
								class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
							>
								<label>Mostrar</label>
								<v-select
									v-model="perpage"
									:options="perPageOptions"
									:clearable="false"
									class="per-page-selector d-inline-block mx-50"
								/>
								<label class="mr-2">registros</label>
								<feather-icon
									class="cursor-pointer"
									icon="RefreshCcwIcon"
									size="20"
									@click="resetList"
								/>
							</b-col>

							<!-- Search -->
							<!-- <b-col cols="12" xl="8" class="mt-1">
								<div class="container-btns-page-specimens d-flex justify-content-end">
									<div class="d-flex">
										<b-form-input
											v-model="search_input"
											class="d-inline-block mr-1"
											placeholder="Buscar por lote..."
											debounce="500"
											@keyup.enter="resetList"
										/>
									</div>
								</div>
							</b-col> -->
						</b-row>
					</div>
					<div class="table-responsive">
						<b-table
							:items="myProvider"
							:fields="fields"
							sticky-header="40vh"
							:current-page="current_page"
							:per-page="perpage"
							responsive
							small
							show-empty
							striped
							ref="table-list"
							table-class="text-nowrap"
						>
							<template #table-busy>
								<div class="text-center text-primary my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Cargando...</strong>
								</div>
							</template>
							<template #cell(date)="data">
								<div class="d-flex justify-content-center">
									<span>{{ data.item.date | myGlobalDayShort }}</span>
								</div>
							</template>

							<!-- <template #cell(provider)="data">
								<div class="">
								 {{data.item.provider.provider_name || "-"}}
								</div>
							</template> -->

							<!-- <template #cell(provider_name)="data">
								<div class="">
									<ViewImage :specimen="data.item.provider" v-if="data.item.type == 'input'" />
									<span v-else>---</span>
								</div>
							</template> -->
							<template #cell(type)="data">
								<div class="d-flex justify-content-center">
									<b-badge v-if="data.item.type == 'input'" variant="success">Ingreso</b-badge>
									<b-badge v-else variant="danger">Salida</b-badge>
								</div>
							</template>
							<template #cell(date_of_entry)="data">
								<div class="d-flex d-code">
									<span>{{ data.item.date_of_entry | myGlobalDayShort }}</span>
								</div>
							</template>
							<template #cell(input)="data">
								<div class="d-flex justify-content-center">
									<span v-if="data.item.input > 0" class="text-success font-weight-bold">
										+ {{ data.item.input }}
										<span style="margin-left: 5px; font-size: 12px">
											({{ data.item.packaging }})
										</span>
									</span>
									<span v-else>-</span>
								</div>
							</template>
							<template #cell(output)="data">
								<div class="d-flex justify-content-center">
									<span v-if="data.item.output > 0" class="text-danger font-weight-bold">
										- {{ data.item.output }}
										<span style="margin-left: 5px; font-size: 12px">
											({{ data.item.packaging }})
										</span>
									</span>
									<span v-else>-</span>
								</div>
							</template>
							<template #cell(current_balance)="data">
								<div class="d-flex justify-content-center">
									<span class="text-primary font-weight-bold">{{ data.item.current_balance }}</span>
								</div>
							</template>
						</b-table>
					</div>
				</b-card>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">cerrar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import Fields from "@/views/amg/management/views/warehouse/data/fields-kardex.data.js"
import WarehouseService from "@/views/amg/management/views/warehouse/services/warehouse.service.js"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	props: {
		info: {
			required: true,
		},
	},
	components: { ViewImage },

	directives: { Ripple },
	mixins: [modalMixin],
	data() {
		return {
			form: {
				id: null,
				code: null,
				name: null,
				description: null,
			},
			fields: Fields,
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			search_input: "",
		}
	},
	async mounted() {
		this.toggleModal("modal-kardex")
	},
	async created() {
		this.isPreloading()
		try {
			this.form.id = this.info.id
			this.form.code = this.info.code
			this.form.name = this.info.name
			this.form.description = this.info.description
			await Promise.all([])
		} catch (error) {
			console.log(error)
		} finally {
			this.isPreloading(false)
		}
	},
	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			try {
				const params = {
					product_id: this.form.id,
					page: ctx.currentPage,
					perpage: ctx.perPage,
					start_date: null,
					end_date: null,
				}
				const { data } = await WarehouseService.getKardex(params)
				this.start_page = data.data.from
				this.to_page = data.data.to
				this.total_data = data.data.total
				this.current_page = data.data.current_page
				return data.data.data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		resetList() {
			this.$refs["table-list"].refresh()
		},
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>
