<template>
	<div>
		<b-modal
			ref="modal-tracking-outputs"
			:title="`Tracking Salida de stock`"
			@hidden="$emit('close')"
			cancel-title="Cancelar"
			scrollable
			size="xsm"
			title-tag="h3"
		>
			<b-card no-body class="mb-0">
				<div class="table-responsive mb-0">
					<b-table
						:items="myProvider"
						:fields="fields"
						sticky-header="40vh"
						primary-key="id"
						responsive
						small
						show-empty
						ref="table-list"
						striped
						table-class="text-nowrap"
						class="mb-0"
					>
						<template #table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Cargando...</strong>
							</div>
						</template>
						<template #cell(quantity)="data">
							<div class="d-flex justify-content-center">
								<span class="text-danger font-weight-bold">{{ data.item.quantity }}</span>
							</div>
						</template>
						<template #cell(created_by)="data">
							<div class="d-flex flex-column justify-content-center align-items-center">
								<span>{{ data.item.created_by }}</span>
								<span>{{ data.item.created_at | myGlobalDayWithHour }}</span>
							</div>
						</template>
					</b-table>
				</div>
			</b-card>

			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">cerrar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import wareService from "@/views/amg/management/views/warehouse/services/warehouse.service.js"
export default {
	props: {
		info: {
			required: true,
		},
	},
	data() {
		return {
			fields: [
				{
					key: "quantity",
					label: "Salida",
					thClass: "text-center",
					tdClass: "text-center",
					visible: true,
				},
				{
					key: "created_by",
					label: "Responsable",
					thClass: "text-center",
					tdClass: "text-center",
					visible: true,
				},
			],
			form: {
				name: null,
			},
		}
	},
	directives: { Ripple },
	mixins: [modalMixin],

	async mounted() {
		this.toggleModal("modal-tracking-outputs")
	},

	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			try {
				const { data } = await wareService.getTrackingOutputs(this.info.product_input_id)
				return data.data
			} catch (error) {
				console.log("error: ", error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		closeModal() {
			this.$emit("close")
		},
		resetList() {
			this.$refs["table-list"].refresh()
		},
	},
}
</script>
<style scoped></style>
