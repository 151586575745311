import axios from "@/axios"
const url = "/api/brain/warehouse/products/measurement-units"

class WeightService {
	async getWeight(params) {
		try {
			const data = await axios.get(`${url}/index`, { params })
			return data.data
		} catch (error) {
			throw error
		}
	}

	async createWeight(datas) {
		try {
			const data = await axios.post(`${url}/save`, datas)
			console.log(data)
			return data.data
		} catch (error) {
			throw error
		}
	}

	async deleteWeight(id) {
		try {
			const data = await axios.delete(`${url}/destroy/${id}`)
			return data.data
		} catch (error) {
			throw error
		}
	}

	async getWeightForSelect(params) {
		try {
			const data = await axios.get(`${url}/get-measurements-units`, { params })
			return data.data
		} catch (error) {
			throw error
		}
	}

	async updateWeight(id, datas) {
		try {
			const data = await axios.put(`${url}/edit/${id}`, datas)
			return data.data
		} catch (error) {
			throw error
		}
	}
	async getWeightSelect(params) {
		try {
			const data = await axios.get(`${url}/get-measurements-units`, { params })
			return data.data
		} catch (error) {
			throw error
		}
	}
}

export default new WeightService()
