export default [
	// {
	// 	key: "#",
	// 	label: "#",
	// 	thClass: "text-center",
	// 	tdClass: "text-center",
	// 	visible: true,
	// },

	{
		key: "code",
		label: "lote",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},

	{
		key: "provider",
		label: "proveedor",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
	// {
	// 	key: "logo",
	// 	label: "Logo",
	// 	thClass: "text-center",
	// 	tdClass: "text-center",
	// 	visible: true,
	// },
	{
		key: "brand",
		label: "Marca",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},

 


	{
		key: "date_of_entry",
		label: "Fecha de ingreso",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
	{
		key: "expiration_date",
		label: "fecha de vencimiento",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
	{
		key: "presentation",
		label: "Presentacion",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
	{
		key: "med",
		label: "Unid. Medida",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
	{
		key: "stock",
		label: "Stock inicial",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
	{
		key: "current_balance",
		label: "Stock actual",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
]
