export default [
	// {
	// 	key: "provider_name",
	// 	label: "logo",
	// 	thClass: "text-center",
	// 	tdClass: "text-center",
	// 	visible: true,
	// },
	// {
	// 	key: "provider",
	// 	label: "Nombre",
	// 	thClass: "text-center",
	// 	tdClass: "text-center",
	// 	visible: true,
	// },

    {
		key: "date",
		label: "Fecha",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
    {
		key: "type",
		label: "Tipo",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},



	// {
	// 	key: "name",
	// 	label: "Un. Medida",
	// 	thClass: "text-center",
	// 	tdClass: "text-center",
	// 	visible: false,
	// },
	// {
	// 	key: "packaging",
	// 	label: "Presentacion",
	// 	thClass: "text-center",
	// 	tdClass: "text-center",
	// 	visible: true,
	// },
    {
		key: "old_balance",
		label: "Inv. inicial",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
    {
		key: "input",
		label: "Entrada",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
    {
		key: "output",
		label: "Salida",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
	{
		key: "current_balance",
		label: "Inv. final",
		thClass: "text-center",
		tdClass: "text-center",
		visible: true,
	},
]