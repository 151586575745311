var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-Create",attrs:{"title":_vm.form.id
			? 'Actualizar presentacion'
			: _vm.type === 'specific'
			? 'Agregar una unidad de medida'
			: 'Agregar presentacion',"cancel-title":"Cancelar","scrollable":"","size":"md","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.form.id ? _vm.UpdateWeight() : _vm.registerWeight()}}},[_vm._v(" "+_vm._s(_vm.form.id ? "Actualizar" : "Guardar")+" ")])]},proxy:true}])},[_c('div',[_c('ValidationObserver',{ref:"formCategories"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre")]),_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null,"type":"text","placeholder":("" + (_vm.type === 'specific'
									? 'Ingrese nombre de una presentacion'
									: 'Ingrese nombre de la unidad de medida'))},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":_vm.type === 'specific' ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Descripción")]),_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null,"type":"text","placeholder":("" + (_vm.type === 'specific'
									? 'Ingrese descripción de una presentacion'
									: 'Ingrese descripción de unidad de medida'))},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }