import axios from "@/axios"

const url = "/api/get-responsibles"

 
class responsibleService {
async getResponsibles() {
    try {
        const data = await axios.get(url)
        return data.data
    } catch (error) {
        throw error
    }
}
async getUnitWeightDetailByid(id,type,brand_id) {
    try {
        const params = {
            type,
            brand_id
        }
        const data = await axios.get(`/api/brain/warehouse/products/measurement-units/${id}/get-weight-details`,{params})
        return data.data
    } catch (error) {
        throw error
    }
}






}

export default new responsibleService()
