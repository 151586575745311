<template>
	<div>
		<b-modal
			ref="modal-Create"
			:title="`Detalle de producto`"
			@hidden="$emit('close')"
			cancel-title="Cancelar"
			scrollable
			title-tag="h3"
			size="xlg"
		>
			<div>
				<ValidationObserver ref="formCategories">
					<b-row>
						<b-col md="6">
							<ValidationProvider v-slot="{ errors }" rules="required" class="w-100">
								<b-form-group :state="errors[0] ? false : null" label="Codigo">
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										v-model="info.code"
										placeholder=""
										readonly
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
						<b-col md="6">
							<ValidationProvider v-slot="{ errors }" rules="required" class="w-100">
								<b-form-group :state="errors[0] ? false : null" label="Nombre producto">
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										v-model="info.name"
										placeholder=""
										readonly
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="6">
							<ValidationProvider v-slot="{ errors }" rules="required" class="w-100">
								<b-form-group :state="errors[0] ? false : null" label="Unidad de medida">
									<!-- <b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										v-model="info.measurement_unit"
										placeholder=""
										readonly
									/> -->
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										v-model="info.category"
										placeholder=""
										readonly
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
						<b-col md="6">
							<ValidationProvider v-slot="{ errors }" rules="required" class="w-100">
								<b-form-group :state="errors[0] ? false : null" label="Stock actual">
									<b-form-input
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										v-model="info.current_stock"
										placeholder=""
										readonly
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
					</b-row>
				</ValidationObserver>
			</div>

			<b-card no-body class="mb-0">
				<div class="m-1">
					<!-- Paginator -->
					<div class="mx-1 mb-1">
						<b-row>
							<b-col
								cols="12"
								sm="6"
								class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
							>
								<span class="text-muted">
									Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
								</span>
							</b-col>
							<!-- Pagination -->
							<b-col
								cols="12"
								sm="6"
								class="d-flex align-items-center justify-content-center justify-content-sm-end"
							>
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									class="mb-0 mt-1 mt-sm-0"
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</b-col>
						</b-row>
					</div>

					<b-row>
						<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
							<label>Mostrar</label>
							<v-select
								v-model="perpage"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
							/>
							<label class="mr-2">registros</label>
							<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetList" />
						</b-col>

						<!-- Search -->
						<b-col cols="12" xl="8" class="mt-1">
							<div class="container-btns-page-specimens d-flex justify-content-end">
								<div class="d-flex">
									<b-form-input
										v-model="search_input"
										class="d-inline-block mr-1"
										placeholder="Buscar por lote..."
										debounce="500"
										@keyup.enter="resetList"
									/>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>

				<div class="table-responsive">
					<b-table
						:items="myProvider"
						:fields="fields"
						sticky-header="40vh"
						primary-key="id"
						:current-page="current_page"
						:per-page="perpage"
						responsive
						small
						show-empty
						ref="table-list"
						striped
						table-class="text-nowrap"
					>
						<template #table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Cargando...</strong>
							</div>
						</template>
						<template #cell(#)="data">
							<div class="d-flex d-code">
								<span>{{ data.index + 1 }}</span>
							</div>
						</template>
						<template #cell(code)="data">
							<div class="d-flex d-code">
								<span>{{ data.item.code }}</span>
							</div>
						</template>
						<template #cell(logo)="data">
							<div class="d-flex d-code">
 								<div>
 									<ViewImage :specimen="data.item.brand" />
									<!-- <pre>{{ data.item }}</pre> -->
									<!-- <span>{{ data.item.brand.name }}</span> -->

								</div>
							</div>
						</template>
						<template #cell(brand)="data">
							<div class="d-flex d-code">
 								<div>
 									<!-- <ViewImage :specimen="data.item.brand" /> -->
									<!-- <pre>{{ data.item }}</pre> -->
									<span>{{ data.item.brand.name }}</span>

								</div>
							</div>
						</template>
 
						<template #cell(provider)="data">
							<div class="d-flex d-code">
 								<div>
 									<!-- <ViewImage :specimen="data.item.brand" /> -->
									<!-- <pre>{{ data.item }}</pre> -->
									<span>{{ data.item.provider.name }}</span>


								</div>
							</div>
						</template>



						<template #cell(name)="data">
							<div class="d-flex d-code">
								<span>{{ data.item.name }}</span>
							</div>
						</template>
						<template #cell(date_of_entry)="data">
							<div class="d-flex d-code">
								<span>{{ data.item.date_of_entry | myGlobalDayShort }}</span>
							</div>
						</template>
						<template #cell(expiration_date)="data">
							<div class="d-flex d-code">
								<span>{{ data.item.expiration_date | myGlobalDayShort }}</span>
							</div>
						</template>
						<template #cell(stock)="data">
							
							<div class="d-flex d-code">
								<span class="text-primary font-weight-bold" style="font-size: 15px">
									{{ data.item.stock }}
								</span>
							</div>
						</template>
						<template #cell(current_balance)="data">
							<div class="d-flex align-items-center">
								<div class="w-50 text-right">
									<span
										class="font-weight-bold"
										:class="data.item.current_balance > 0 ? 'text-success' : 'text-danger'"
										style="font-size: 15px"
									>
										{{ data.item.current_balance }}
									</span>
								</div>
								<div class="w-50 text-left">
									<b-button
										v-if="data.item.current_balance < data.item.stock"
										variant="success"
										class="btn btn-sm btn-icon ml-1"
										@click="showTrackingOutputs(data.item)"
									>
										<feather-icon icon="ListIcon" />
									</b-button>
								</div>
							</div>
						</template>
					</b-table>
				</div>
			</b-card>

			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">cerrar</b-button>
			</template>
		</b-modal>

		<TrackingOutputsModal v-if="showTrackingOutput" :info="infoInput" @close="showTrackingOutput = false" />
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import Fields from "@/views/amg/management/views/warehouse/data/fields.data.list.js"
import wareService from "@/views/amg/management/views/warehouse/services/warehouse.service.js"
import TrackingOutputsModal from "@/views/amg/management/views/warehouse/components/TrackingOutputsModal.vue"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	props: {
		info: {
			required: true,
		},
	},
	components: {
		TrackingOutputsModal,
		ViewImage
	},
	data() {
		return {
			fields: Fields,
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			search_input: "",
			form: {
				name: null,
			},
			infoInput: {},
			showTrackingOutput: false,
		}
	},
	directives: { Ripple },
	mixins: [modalMixin],

	async mounted() {
		this.toggleModal("modal-Create")
	},

	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				search: this.search_input,
				page: ctx.currentPage,
				perpage: ctx.perPage,
			}
			try {
				const { data } = await wareService.getProductById(this.info.id, params)
				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page
				return data.data
			} catch (error) {
				console.log("error: ", error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		showTrackingOutputs(item) {
			this.infoInput = item
			this.showTrackingOutput = true
		},

		closeModal() {
			this.$emit("close")
		},
		resetList() {
			this.$refs["table-list"].refresh()
		},
	},
}
</script>
<style scoped>
.d-code {
	width: 100% !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 1px !important;
}
.image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

</style>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>
