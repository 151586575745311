<template>
	<b-modal
		ref="modal-Create"
		@hidden="$emit('close')"
		:title="
			form.id
				? 'Actualizar presentacion'
				: type === 'specific'
				? 'Agregar una unidad de medida'
				: 'Agregar presentacion'
		"
		cancel-title="Cancelar"
		scrollable
		size="md"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div>
			<ValidationObserver ref="formCategories">
				<b-col>
					<ValidationProvider v-slot="{ errors }" rules="required">
						<b-form-group :state="errors[0] ? false : null">
							<label for="name">Nombre</label>
							<b-form-input
								id="name"
								:state="errors[0] ? false : null"
								type="text"
								v-model="form.name"
								:placeholder="`${
									type === 'specific'
										? 'Ingrese nombre de una presentacion'
										: 'Ingrese nombre de la unidad de medida'
								}`"
							/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col>
					<ValidationProvider v-slot="{ errors }" :rules="type === 'specific' ? '' : 'required'">
						<b-form-group :state="errors[0] ? false : null">
							<label for="name">Descripción</label>
							<b-form-input
								id="name"
								:state="errors[0] ? false : null"
								type="text"
								v-model="form.description"
								:placeholder="`${
									type === 'specific'
										? 'Ingrese descripción de una presentacion'
										: 'Ingrese descripción de unidad de medida'
								}`"
							/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
			<b-button variant="primary" @click="form.id ? UpdateWeight() : registerWeight()">
				{{ form.id ? "Actualizar" : "Guardar" }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import serviceWeight from "@/views/brain/administrative/views/weight-units/services/weight.service.js"
export default {
	props: {
		info: {
			required: true,
		},
		type: {
			require: true,
		},
	},
	directives: { Ripple },
	mixins: [modalMixin],
	data() {
		return {
			form: {
				id: null,
				name: null,
				description: null,
			},
		}
	},
	async mounted() {
		this.toggleModal("modal-Create")
	},
	created() {
		if (this.info.id != null) {
			this.form.id = this.info.id
			this.form.name = this.info.name
			this.form.description = this.info.description
		}
	},

	computed: {
		message() {
			return this.type == "specific"
				? "Unidad de medida agregado correctamente"
				: "Presentacion agregado correctamente"
		},
	},

	methods: {
		async registerWeight() {
			const validate = await this.$refs.formCategories.validate()
			if (!validate) return

			const { isConfirmed } = await this.showConfirmSwal({
				text: `Registrar ${this.type == "specific" ? "unidad de medida" : "presentacion"}: ${this.form.name}.`,
			})
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const params = {
					name: this.form.name,
					description: this.form.description,
					type: this.type,
				}
				const data = await serviceWeight.createWeight(params)
				this.showSuccessToast(data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
				this.$refs.formCategories.reset()
				this.form.name = null
				this.form.description = null
			} finally {
				this.isPreloading(false)
			}
		},

		async UpdateWeight() {
			const validate = await this.$refs.formCategories.validate()
			if (!validate) return

			const { isConfirmed } = await this.showConfirmSwal({
				text: `Actualizando ${this.type == "specific" ? "unidad de medida" : "presentacion"}: ${
					this.form.name
				}.`,
			})
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const params = {
					name: this.form.name,
					description: this.form.description,
				}
				const updated = await serviceWeight.updateWeight(this.form.id, params)
				this.form.id = null
				this.form.name = null
				this.form.description = null

				this.showSuccessToast(updated.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		closeModal() {
			this.$emit("close")
		},
	},
}
</script>
